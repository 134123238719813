export var globalComponents = {
    'grid-new': { vm: () => import('components/grid/grid') },
    'attachments-grid': { vm: () => import('components/attachments/grid/grid') },
    'attachments-box': { vm: () => import('components/attachments/box/box') },
    'photoset-new': { vm: () => import('components/photoset/photoset') },
    'wcc-slider': { vm: () => import('components/slider/slider') },
    'pinboard': { vm: () => import('components/pinboard/viewmodel') },
    'overlay': { vm: () => import('components/overlay/overlay') },
    'video-overlay-stage': { vm: () => import('components/videoOverlay/stage') },
    'thread-editing-overlay-stage': { vm: () => import('components/threadEditingOverlay/stage') },
    'dd-slider': { vm: () => import('components/ddSlider/ddSlider') },
    'dd-stack': { vm: () => import('components/ddStack/ddStack') },
    'range-slider': { vm: () => import('components/rangeSlider/rangeSlider') },
    'wcc-dialog': { vm: () => import('components/dialog/dialog') },
    'bootstrap-dialog': { vm: () => import('components/bootstrapDialog/bootstrapDialog') },
    'expandable-control': { vm: () => import('components/expandableControl/expandableControl') },

    'ace-editor': { vm: () => import('components/editors/ace/ace') },
    'master-editor': { vm: () => import('components/editors/master/master') },
    'master-editor-preview-inner': { vm: () => import('components/editors/master/preview/inner/viewmodel') },
    'master-viewer': { vm: () => import('components/editors/master/viewer/viewer') },
    'content-box': { vm: () => import('components/editors/content/box/box') },
    'content-box-frame': { vm: () => import('components/editors/content/box/frame') },
    'content-box-frame-inner': { vm: () => import('components/editors/content/box/frame-inner') },
    'content-box-preview-inner': { vm: () => import('components/editors/content/box/preview-inner') },
    'content-box-viewer': { vm: () => import('components/editors/content/box/viewer') },
    'text-editor': { vm: () => import('components/editors/text/text') },
    'number-editor': { vm: () => import('components/editors/number/number') },
    'attachment-title-editor': { vm: () => import('components/editors/attachments/title/viewmodel') },
    'chat-editor': { vm: () => import('components/editors/chat/chat') },
    'thread-translation-editor': { vm: () => import('components/editors/threadTranslation/threadTranslation') },
    'wcc-image-editor': { vm: () => import('components/editors/image/image') },
    'blanks-editor': { vm: () => import('components/editors/blanks/blanks') },
    'ranking-form': { vm: () => import('components/rankingForm/rankingForm') },
    'wcc-html-editor': { vm: () => import('components/editors/html/html') },
    'websites-editor': { vm: () => import('components/editors/websites/websites') },

    'limitedinput': { vm: () => import('components/inputs/limited/viewmodel') },
    'floatinginput': { vm: () => import('components/floatingInput/viewmodel') },
    'phone-number-input': { vm: () => import('components/inputs/phoneNumber/viewmodel') },

    'topic-statistics': { vm: () => import('components/statistics/topic/viewmodel') },
    'survey-statistics': { vm: () => import('components/statistics/survey/survey') },
    'topics-completion-statistics': { vm: () => import('components/statistics/topicsCompletion/viewmodel') },
    'person-statistics': { vm: () => import('components/statistics/person/viewmodel') },
    'wcs-topics-completion': { vm: () => import('components/people/topicCompletions/viewmodel') },

    'pixabay-picker': { vm: () => import('components/imagepickers/external/pixabay') },
    'unsplash-picker': { vm: () => import('components/imagepickers/external/unsplash') },
    'composite-images-picker': { vm: () => import('components/imagepickers/composite/composite') },
    'icons-picker': { vm: () => import('components/iconsPicker/iconsPicker') },
    'wcc-avatar-picker': { vm: () => import('components/avatar/picker/picker') },
    'emojis-picker': { vm: () => import('viewmodels/emojis/picker'), template: { dynamicView: '_emojisPicker' } },
    'snapshots-picker': { vm: () => import('components/snapshotsPicker/snapshotsPicker') },
    'wcc-colorpicker': { vm: () => import('components/colorPicker/viewmodel') },
    'qualzy-color-picker-basic': { vm: () => import('components/colorPicker/basic/colorPickerBasic') },
    'wcc-emojispicker': { vm: () => import('components/emojisPicker/emojisPicker') },

    'tags-editor': { vm: () => import('components/tags/editor/editor') },
    'tags-selector': { vm: () => import('components/tags/selector/selector') },
    'tag-picker': { vm: () => import('components/tags/picker/picker') },
    'tags-list': { vm: () => import('components/tags/list/list') },
    'entity-tag': { vm: () => import('components/tags/entityTag/viewmodel') },
    'tag': { vm: () => import('components/tags/tag/tag') },

    'fancybox-image-new': { vm: () => import('components/fancybox/image') },
    'fancybox-inline-new': { vm: () => import('components/fancybox/inline') },
    'fancybox-inline': { vm: () => import('components/fancybox/inline'), template: 'wcc-components-fancybox-inline-new' },
    'fancybox-iframe-new': { vm: () => import('components/fancybox/iframe') },
    'fancybox-video-iframe-new': { vm: () => import('components/fancybox/videoIframe') },
    'fancybox-youtube-new': { vm: () => import('components/fancybox/youtube') },
    'fancybox-vimeo-new': { vm: () => import('components/fancybox/vimeo') },

    'content-resizer': { vm: () => import('components/contentResizer/contentResizer') },
    'content-frame': { vm: () => import('components/contentFrame/viewmodel') },
    'content-block': { vm: () => import('components/contentBlock/contentBlock') },
    'progress-multibar': { vm: () => import('components/progressMultibar/progressMultibar') },
    'progress-cover': { vm: () => import('components/progressCover/viewmodel') },
    'progress-ring': { vm: () => import('components/progressRing/progressRing') },
    'wcc-droparea': { vm: () => import('components/droparea/droparea') },
    'staging-tools': { vm: () => import('components/staging/tools/tools') },
    'password-policy': { vm: () => import('components/passwordPolicy/viewmodel') },
    'sortable-list': { vm: () => import('components/sortable/list/list') },
    'sortable-grid': { vm: () => import('components/sortable/grid/grid') },
    'sliding-container': { vm: () => import('components/slidingContainer/slidingContainer') },
    'sliding-label': { vm: () => import('components/slidingLabel/slidingLabel') },
    'infinite-list': { vm: () => import('components/infiniteList/infiniteList') },
    'qr-code': { vm: () => import('components/qrcode/viewmodel') },
    'wcc-alert': { vm: () => import('components/alert/alert') },
    'vote-bubble': { vm: () => import('components/voteBubble/voteBubble') },
    'ideation-vote-picker': { vm: () => import('components/ideationVotePicker/ideationVotePicker') },

    'wcc-heatmap': { vm: () => import('components/heatmap/heatmap') },
    'wcc-classic-survey': { vm: () => import('components/classicSurvey/classicSurvey') },
    'wcc-password-reset': { vm: () => import('components/passwordReset/viewmodel') },
    'cards-rating-matrix': { vm: () => import('components/cardsRatingMatrix/cardsRatingMatrix') },
    'cards-sorter-desktop': { vm: () => import('components/cardsSorter/desktop/desktop') },
    'cards-sorter-mobile': { vm: () => import('components/cardsSorter/mobile/mobile') },
    'cards-scorer': { vm: () => import('components/cardsScorer/cardsScorer') },
    'cards-score-answers': { vm: () => import('components/cardsScoreAnswers/cardsScoreAnswers') },
    'topic-questions': { vm: () => import('components/topicQuestions/topicQuestions') },
    'screen-recorder': { vm: () => import('components/screenRecorder/screenRecorder') },
    'audio-recorder': { vm: () => import('components/audioRecorder/audioRecorder') },
    'wcc-video-recorder': { vm: () => import('components/videoRecorder/videoRecorder') },
    'wcc-video-picker': { vm: () => import('components/wccVideoPicker/wccVideoPicker') },
    'audio-picker': { vm: () => import('components/audioPicker/audioPicker') },
    'media-picker-dialog': { vm: () => import('components/mediaPickerDialog/mediaPickerDialog') },
    'media-picker-box': { vm: () => import('components/mediaPickerBox/mediaPickerBox') },
    'video-presentation': { vm: () => import('components/videoPresentation/videoPresentation') },
    'person-redemptions': { vm: () => import('components/redemptions/viewmodel') },
    'sms-link-sender': { vm: () => import('components/sms/linkSender/viewmodel') },
    'login': { vm: () => import('components/login/viewmodel') },
    'data-grid': { vm: () => import('components/dataGrid/dataGrid') },
    'words-cloud': { vm: () => import('components/wordsCloud/wordsCloud') },

    /// to remove once temporary CP coupling is over
    'community-cp-widget-launcher': { vm: () => import('components/community/widgets/contactprofiler/cp-widget-launcher') },
    'cp-redemptions': { vm: () => import('components/redemptions/cp/viewmodel') },
    'cp-redemption-history': { vm: () => import('components/community/widgets/contactprofiler/redemptions/redemptionhistory') },
    'cp-redemption-offers': { vm: () => import('components/community/widgets/contactprofiler/redemptions/redemptionoffers') },
    'cp-participation-history': { vm: () => import('components/community/widgets/contactprofiler/activity/participationhistory') },
    'cp-availablejobs': { vm: () => import('components/community/widgets/contactprofiler/jobs/availablejobs') },
    'cp-referrerlink': { vm: () => import('components/community/widgets/contactprofiler/referral/referrerlink') },

    'community-projects': { vm: () => import('components/community/widgets/projects/projects') },

    'wcc-generic-attachment': { vm: () => import('components/attachments/generic/generic') },
    'wcc-image': { vm: () => import('components/attachments/image/image') },
    'wcc-video': { vm: () => import('components/attachments/video/video') },
    'wcc-audio': { vm: () => import('components/attachments/audio/audio') },
    'wcc-youtube-video': { vm: () => import('components/attachments/youtube/youtube') },
    'wcc-vimeo-video': { vm: () => import('components/attachments/vimeo/vimeo') },
    'wcc-file': { vm: () => import('components/attachments/file/file') },
    'wcc-fluid-icon': { vm: () => import('components/attachments/fluidIcon/fluidIcon') },
    'wcc-link': { vm: () => import('components/attachments/link/link') },
    'wcc-upload': { vm: () => import('components/attachments/upload/upload') },
    
    'wcc-attachment-box-layout': { vm: () => import('components/attachments/layouts/box/box') },
    'wcc-attachment-panel-layout': { vm: () => import('components/attachments/layouts/panel/panel') },
    'wcc-attachment-row-layout': { vm: () => import('components/attachments/layouts/row/row') },
    'attachment-transcript': { vm: () => import('components/attachments/transcript/transcript') },
    'attachment-caption': { vm: () => import('components/attachments/decorators/caption/caption') },
    'attachment-controls': { vm: () => import('components/attachments/decorators/controls/controls') },

    'wcc-player-youtube': { vm: () => import('components/players/youtubePlayer'), template: 'wcc-components-wcc-player' },
    'wcc-player-vimeo': { vm: () => import('components/players/vimeoPlayer'), template: 'wcc-components-wcc-player' },
    'wcc-player-s3': { vm: () => import('components/players/s3Player'), template: 'wcc-components-wcc-player-s3' },
    
    'wcc-select': { vm: () => import('components/dropdowns/select/select') },
    'wcc-multiselect': { vm: () => import('components/dropdowns/multiselect/multiselect') },
    'wcc-list': { vm: () => import('components/dropdowns/list/list') },
    'icheck-multiselect': { vm: () => import('components/icheck/multiselect/multiselect') },
    'icheck-select': { vm: () => import('components/icheck/select/select') },
    'icheck-item': { vm: () => import('components/icheck/item/item') },
    'wcc-select-2': { vm: () => import('components/select2/select2') },
    'translation-checkbox': { vm: () => import('components/controls/translationCheckbox/translationCheckbox') },
    'wcc-timezone': { vm: () => import('components/controls/timezone/timezone') },
    'wcc-sms-verification': { vm: () => import('components/smsverification/smsverification') },    
    'wcc-two-factor-auth-setup': { vm: () => import('components/security/twofactorauth/twofactorauthsetup') },
    'wcc-two-factor-auth-verify': { vm: () => import('components/security/twofactorauth/twofactorauthverify') },

    'task-activity': { vm: () => import('components/task/activity/activity') },
    'task-topics-slider': { vm: () => import('components/task/topics/slider/slider') },
    'task-topics-indicators': { vm: () => import('components/task/topics/indicators/viewmodel') },
    'task-topics-list': { vm: () => import('components/task/topics/list/list') },
    'task-content-image': { vm: () => import('components/task/contentImage/contentImage') },
    'task-image': { vm: () => import('components/task/image/image') },
    'task-terms': { vm: () => import('components/task/terms/terms') },

    'topic-image': { vm: () => import('components/topic/image/image') },
    'topic-content-image': { vm: () => import('components/topic/contentImage/contentImage') },
    'topic-box': { vm: () => import('components/topic/box/box') },
    'topic-content': { vm: () => import('components/topic/content/content') },
    'topic-summary': { vm: () => import('components/topic/summary/summary') },
    'topic-summary-instructions-header': { vm: () => import('components/topic/summary/instructionsHeader/instructionsHeader') },
    'topic-attachments': { vm: () => import('components/topic/attachments/attachments') },
    'topic-comments': { vm: () => import('components/topic/comments/comments') },
    'topic-comment': { vm: () => import('components/topic/comment/comment') },
    'topic-heatmap': { vm: () => import('components/topic/heatmap/heatmap') },
    'topic-card': { vm: () => import('components/topic/card/card') },
    'topic-comments-filters': { vm: () => import('components/topic/commentsFilters/commentsFilters') },
    'topic-usercontent-standalone': { vm: () => import('components/topic/userContent/standalone') },
    'topic-usercontent-mobile': { vm: () => import('components/topic/userContent/mobile') },
    'topic-usercontent-inline': { vm: () => import('components/topic/userContent/inline') },
    'topic-comments-table': { vm: () => import('components/topic/commentsTable/commentsTable') },
    'topic-desktop-menu': { vm: () => import('components/topic/menus/desktop/desktop') },
    'topic-mobile-menu': { vm: () => import('components/topic/menus/mobile/mobile') },
    'topic-classic-survey': { vm: () => import('components/topic/classicSurvey/classicSurvey') },

    'thread-media': { vm: () => import('components/topic/threadMedia/threadMedia') },

    'comment-editor-standalone': { vm: () => import('components/editors/thread/text/comment/standaloneComment') },
    'comment-editor-mobile': { vm: () => import('components/editors/thread/text/comment/mobileComment') },
    'comment-editor-inline': { vm: () => import('components/editors/thread/text/comment/inlineComment') },
    'reply-editor-standalone': { vm: () => import('components/editors/thread/text/reply/standaloneReply') },
    'reply-editor-inline': { vm: () => import('components/editors/thread/text/reply/inlineReply') },
    'reply-suggestion': { vm: () => import('components/editors/thread/text/replySuggestion/replySuggestion') },
    'open-ai-completion': { vm: () => import('components/openAiCompletion/openAiCompletion') },
    'ideation-vote-editor-standalone': { vm: () => import('components/editors/thread/text/ideationVote/standaloneIdeationVote') },
    'ideation-vote-editor-inline': { vm: () => import('components/editors/thread/text/ideationVote/inlineIdeationVote') },
    'survey-editor-standalone': { vm: () => import('components/editors/thread/survey/standaloneSurvey') },
    'survey-editor-mobile': { vm: () => import('components/editors/thread/survey/mobileSurvey') },
    'info-thread-editor': { vm: () => import('components/editors/thread/info/info') },
    'externalsurvey-editor-participant': { vm: () => import('components/editors/thread/externalSurvey/externalSurvey-participant') },
    'externalsurvey-editor-mod': { vm: () => import('components/editors/thread/externalSurvey/externalSurvey-mod') },
    'heatmap-editor-standalone': { vm: () => import('components/editors/thread/heatmap/standaloneHeatmap') },
    'heatmap-editor-mobile': { vm: () => import('components/editors/thread/heatmap/mobileHeatmap') },
    'cards-sort-editor-standalone': { vm: () => import('components/editors/thread/cardsSort/modernStandaloneCardsSort') },
    'cards-sort-editor-mobile': { vm: () => import('components/editors/thread/cardsSort/modernMobileCardsSort') },
    'cards-score-editor-standalone': { vm: () => import('components/editors/thread/cardsScore/standaloneCardsScore') },
    'cards-score-editor-mobile': { vm: () => import('components/editors/thread/cardsScore/mobileCardsScore') },
    'video-presentation-editor-standalone': { vm: () => import('components/editors/thread/videoPresentation/standaloneVideoPresentation') },
    'video-presentation-editor-mobile': { vm: () => import('components/editors/thread/videoPresentation/mobileVideoPresentation') },
    'pinboard-editor-standalone': { vm: () => import('components/editors/thread/pinboard/standalonePinboard') },
    'pinboard-editor-mobile': { vm: () => import('components/editors/thread/pinboard/mobilePinboard') },
    'multicomment-editor-standalone': { vm: () => import('components/editors/thread/multicomment/standaloneMulticomment') },
    'multicomment-editor-mobile': { vm: () => import('components/editors/thread/multicomment/mobileMulticomment') },
    'screenrecording-editor-standalone': { vm: () => import('components/editors/thread/screenRecording/standaloneScreenRecording') },
    'screenrecording-editor-mobile': { vm: () => import('components/editors/thread/screenRecording/mobileScreenRecording') },
    'audiorecording-editor-standalone': { vm: () => import('components/editors/thread/audioRecording/standaloneAudioRecording') },
    'audiorecording-editor-mobile': { vm: () => import('components/editors/thread/audioRecording/mobileAudioRecording') },

    'cb-video': { vm: () => import('components/contentBuilder/widgets/video/video') },
    'cb-support-button': { vm: () => import('components/contentBuilder/widgets/supportButton/supportButton') },

    //'poll-anchor': { vm: () => import('components/polls/anchor/viewmodel') },
    'mandatory-probes-anchor': { vm: () => import('components/mandatoryProbes/anchor/viewmodel') },

    'current-user-avatar': { vm: () => import('components/avatar/currentUser/currentUser') },
    'person-avatar': { vm: () => import('components/avatar/person/person') },
    'wcc-avatar': { vm: () => import('components/avatar/avatar/avatar') },
    'wcc-avatar-editor': { vm: () => import('components/avatar/editor/editor') },
    'wcc-content': { vm: () => import('components/content/content') },
    'wcc-loader': { vm: () => import('components/loader/loader') },
    'spinner': { vm: () => import('viewmodels/spinner'), template:  { dynamicView: '_spinner' } },
    'spinner-new': { vm: () => import('components/spinner/viewmodel') },
    'wcs-spinner': { vm: () => import('components/wcsSpinner/wcsSpinner') },
    'component-awaiter': { vm: () => import('components/componentAwaiter/componentAwaiter') },
    'controls-content-pickers': { vm: () => import('components/controls/contentPickers/contentPickers') },

    'community-box': { vm: () => import('components/community/widgets/box/box') },
    'community-todo': { vm: () => import('components/community/widgets/todo/viewmodel') },
    'community-navigation': { vm: () => import('components/community/widgets/navigation/navigation') },
    'community-avatar': { vm: () => import('components/community/widgets/avatar/avatar') },
    'community-redemptions': { vm: () => import('components/community/widgets/redemptions/viewmodel') },
    'community-redemptions-cp': { vm: () => import('components/community/widgets/redemptions/cp/viewmodel') },
    'community-video': { vm: () => import('components/community/widgets/video/viewmodel') },
    'community-widget-tools': { vm: () => import('components/community/widget/tools/viewmodel') },
    'community-slider': { vm: () => import('components/community/widgets/slider/viewmodel') },
    'community-topic-box': { vm: () => import('components/community/widgets/topicBox/topicBox') },
    'community-activities': { vm: () => import('components/community/widgets/activities/viewmodel') },
    'community-grid': { vm: () => import('components/community/widgets/grid/grid') },
    'community-widget-template-editor': { vm: () => import('components/community/widget/templateEditor/templateEditor') },
    'community-widget-templates-manager': { vm: () => import('components/community/widget/templatesManager/templatesManager') },
    'community-templates-guard': { vm: () => import('components/community/templates/guard/guard') },
    'community-templates-data-editor': { vm: () => import('components/community/templates/dataEditor/dataEditor') },
    // related to community (but accessed via ad-hoc project within community)
    'discussion-community-menu': { vm: () => import('components/community/discussions/navigation/discussionCommunityMenu') },

    'ecokit-community-order-button': { vm: () => import('components/community/widgets/ecokit/orderButton/orderButton') },
    'ecokit-community-order-form': { vm: () => import('components/community/widgets/ecokit/orderForm/orderForm') },

    'cookie-consent': { vm: () => import('components/cookieconsent/cookieconsent') },

    'microphone-selector': { vm: () => import('components/controls/microphoneSelector/component') },
    
    'qualzy-video-recorder': { vm: () => import('../../../components/controls/recorders/qualzyVideoRecorder/component') },
    'qualzy-audio-recorder': { vm: () => import('../../../components/controls/recorders/qualzyAudioRecorder/component') },
    'qualzy-screen-recorder': { vm: () => import('../../../components/controls/recorders/qualzyScreenRecorder/component') },
    'qualzy-screen-video-recorder': { vm: () => import('../../../components/controls/recorders/qualzyScreenVideoRecorder/component') },
    
    'qualzy-endpoint-file-uploader': { vm: () => import('../../../components/controls/uploaders/qualzyEndpointFileUploader/component') },
    'qualzy-s3-file-uploader': { vm: () => import('../../../components/controls/uploaders/qualzyS3FileUploader/component') }
}